<template>
	<v-card flat :color="color" dark class="ma-2">
		<v-card-title v-if="title">
			{{ title }}
			<v-spacer></v-spacer>
			<v-btn icon router-link :to="`/projects/${id}`"
				><v-icon>mdi-arrow-right-circle</v-icon></v-btn
			>
		</v-card-title>
		<v-card-text>
			<v-checkbox
				label="Display As Currently Recruiting"
				v-model="currentlyRecruiting.active"
			></v-checkbox>
			<template v-if="currentlyRecruiting.active">
				<v-text-field
					label="Project Title"
					v-model="currentlyRecruiting.title"
				></v-text-field>
				<v-textarea
					label="Blurb"
					v-model="currentlyRecruiting.blurb"
				></v-textarea>
				<v-text-field
					label="Incentive"
					v-model="currentlyRecruiting.incentive"
				></v-text-field>
				<mw-upload location="uploads" allow-select label="Image" v-model="currentlyRecruiting.image"></mw-upload>
				<v-text-field
					label="Screener"
					hint="Only the last part of the URL"
					permanent-hint
					v-model="currentlyRecruiting.screener"
				></v-text-field>
			</template>
		</v-card-text>
		<v-card-actions>
			<v-spacer></v-spacer>
			<v-btn @click="deleteCard" color="error">Delete</v-btn>
			<v-btn @click="save" color="primary">Save</v-btn>
		</v-card-actions>
	</v-card>
</template>

<script>
import MwUpload from "@c/storage/MwUpload"
export default {
	name: "CurrentlyRecruitingTile",
	props: {
		id: { type: String },
		title: { type: String },
	},
	data: () => {
		return {};
	},
	computed: {
        tiles(){
			return this.$store.state.config.data.currently_recruiting;
        },
		currentlyRecruiting() {
			let recruiting = this.prefill;
			if (this.tiles && this.tiles[this.id]) {
				recruiting = this.tiles[this.id];
			}
            return recruiting;
		},
		project() {
			return this.$store.state.projects.data[this.id];
		},
        color(){
            let keys = Object.keys(this.tiles);
            let i = keys.indexOf(this.id);
            if( i < 0 ){
                return "transparent";
            }
            return this.$store.state.participant_colors[i];
        },
		prefill() {
			if (!this.project) {
				return {};
			}
			return {
				active: false,
				title: this.project.public_name || null,
				incentive: "£" + this.project.incentive || null,
				screener: "",
				blurb: this.project.desription || null,
			};
		},
	},
	components: {
		MwUpload
	},
	methods: {
		save() {
			this.$store.dispatch("config/patch", {
				[`currently_recruiting.${this.id}`]: this.currentlyRecruiting,
			}).then( () => {
                this.$root.$emit("saved")
            })
		},
		deleteCard() {
			this.$store.dispatch(
				"config/delete",
				`currently_recruiting.${this.id}`
			);
		},
	},
	created() {
		this.$store.dispatch("projects/fetchById", this.id);
	},
};
</script>
